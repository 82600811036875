/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from 'react-helmet'
//import { useStaticQuery, graphql, Link } from "gatsby"
//import {  Link } from "gatsby"

//import Header from "./header"
import "./layout.css"

import { Col, Container, Navbar, Nav } from "react-bootstrap"
import logo from "../images/Logo_zi_brunner_30.png"

const Layout = ({ children }) => {
/*   const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
 */
  return (
    <>
      <Helmet>
          <body className="boxed-layout container" />
      </Helmet>
      <header>
        <Navbar bg="light" expand="sm">
          <Navbar.Brand href="/"><img src={logo} alt="ZI Brunner"></img></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
            <Nav >
              <Nav.Link href="/impressum/">Impressum</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </header>
      <Container>
        <main>{children}</main>
      </Container>
      <footer>
        <Col bg="light" className="text-center">
          © {new Date().getFullYear()}, Dipl.-Ing. Dr. Walter Brunner - Zivilingenieur für Bauingenieurwesen<br/>
          4400 Garsten/Steyr - Pöttmesserweg 4<br/>
          07252 41870 - <a href="mailto:office@zi-brunner.at">office@zi-brunner.at</a>
        </Col>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
